import React from 'react';
import Timer from 'react-compound-timer';
import { useInterval } from 'react-use';

import cl from 'classnames';
/* eslint-disable react/prop-types */
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { useInView } from 'react-cool-inview';

import PromotionIcon from 'shared/CourseLanding/icons/PromotionIcon';
import { colorsV2 } from 'style/colors-v2';
import {
  deviceBreakpoints,
  useDeviceDetect3
} from 'utils/hooks/useDeviceQuery.hook';
import { ResponsiveLayoutWithSsr } from '../../Layout/ResponsiveLayout';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { MONTH_NAME_FORMAT2 } from 'constant';
import moment from 'moment';

const Skeleton = styled.div`
  height: 80px;
  ${fromScreen(560)} {
    height: 60px;
  }
  ${fromScreen(1366)} {
    height: 88px;
  }
`;

const Main = styled.div`
  .compact-top-bar,
  .full-top-bar {
    transition: background-color 1s ease;
  }

  &.color-red {
    .compact-top-bar,
    .full-top-bar {
      background-color: ${colorsV2.red20};
    }
  }

  &.color-blue {
    .compact-top-bar,
    .full-top-bar {
      background-color: ${colorsV2.blue20};
    }
  }

  .children-hidden {
    opacity: 0;
  }

  .children-wrapper {
    top: 0;
    position: absolute;
  }

  a {
    text-decoration: underline;
    color: ${colorsV2.black100};

    &:hover {
      color: ${colorsV2.primary100};

      > * {
        color: ${colorsV2.primary100};
      }
    }
  }
`;

const FullTopBarMain = styled(motion.div)`
  padding: 12px 16px;
  background-color: ${p => p.bgColor || colorsV2.red20};
  width: 100%;
  z-index: 102;

  .grid {
    display: grid;
    gap: 4px;

    &.flow-column {
      grid-auto-flow: column;
    }

    &.align-center {
      align-items: center;
    }

    &.gap-4 {
      flex-wrap: wrap;
    }
  }

  .container {
    max-width: 428px;
    margin: 0 auto;
    display: flex;
  }

  .icon-promotion {
    font-size: 40px;
    margin-right: 14px;
    line-height: 0;
  }

  .info {
    flex: 1;
    position: relative;

    .info-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
    }

    .info-desc {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      font-weight: 400;
    }
  }

  .bold {
    font-weight: 600;
  }

  @media ${deviceBreakpoints.md} {
    .container {
      max-width: 768px;
    }
  }

  @media ${deviceBreakpoints.lg} {
    .icon-promotion {
      font-size: 52px;
    }

    .container {
      max-width: 1120px;
    }
  }
`;

const FixTop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  ${p =>
    p.hidden &&
    css`
      opacity: 0;
    `}
  ${fromScreen(1144)} {
    top: 60px;
  }
`;

const CompactTopBarMain = styled(motion.div)`
  background-color: ${p => p.bgColor || colorsV2.red20};
  padding: 10px 18px;
  opacity: 0;

  .wrapper {
    display: flex;
    place-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .icon-promotion {
      font-size: 20px;
      line-height: 0;
      margin-right: 6px;
    }

    .content {
      position: relative;
    }

    .bold {
      font-weight: 600;
    }
  }

  @media ${deviceBreakpoints.md} {
    .wrapper {
      max-width: 768px;
      margin: 0 auto;
    }
  }
  @media ${deviceBreakpoints.lg} {
    .wrapper {
      max-width: 1120px;
    }
  }
`;

const compactVariant = {
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
};

const fullVariant = {
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
};

const CompactTopBar = React.forwardRef(
  ({ isCompactMode, renderCompact = () => null, data }, ref) => {
    const {
      value,
      remainSlot: remainQuantity,
      initialTime,
      endDate,
      courseName,
      linkUrl,
      linkText
    } = data;

    return (
      <CompactTopBarMain
        className="compact-top-bar"
        animate={isCompactMode ? 'show' : 'hidden'}
        variants={compactVariant}
        transition={{ duration: 0.4, type: 'tween' }}
        ref={ref}
      >
        <div className="wrapper">
          <div className="icon-promotion">
            <PromotionIcon prefix="fixed-scholarship" />
          </div>
          <div className="content">
            <div>
              {renderCompact({
                initialTime,
                value,
                remainQuantity,
                endDate
              }) || (
                <div>
                  <span className="bold">
                    {remainQuantity} suất học bổng lên đến {value}{' '}
                  </span>
                  {courseName && (
                    <span>
                      cho lớp <span className="bold">{courseName}</span>
                    </span>
                  )}
                  {' kết thúc sau '}
                  <span className="bold">
                    <Timer initialTime={initialTime} direction="backward">
                      {() => (
                        <span className="count-down">
                          <Timer.Days />d <Timer.Hours />h <Timer.Minutes />m{' '}
                          <Timer.Seconds />s
                        </span>
                      )}
                    </Timer>
                  </span>{' '}
                  {linkUrl && <Link href={linkUrl}>{linkText}</Link>}
                </div>
              )}
            </div>
            <Typography
              variant="regular/12-20"
              color={colorsV2.black80}
              className="promotion-desc"
            >
              *Ưu đãi HÈ đăng ký trước{' '}
              {moment(endDate).format(MONTH_NAME_FORMAT2)}
            </Typography>
          </div>
        </div>
      </CompactTopBarMain>
    );
  }
);

const FullTopBar = React.forwardRef(
  ({ data, renderTitle = () => null, renderDescription = () => null }, ref) => {
    const {
      value,
      remainSlot: remainQuantity,
      initialTime,
      endDate,
      courseName,
      linkUrl,
      linkText
    } = data;

    return (
      <FullTopBarMain
        className="full-top-bar"
        animate={'show'}
        variants={fullVariant}
        transition={{ duration: 0.2, type: 'tween' }}
        ref={ref}
      >
        <motion.div className="container">
          <div className="icon-promotion">
            <PromotionIcon prefix="fixed-scholarship" />
          </div>
          <motion.div className="info">
            <div>
              {renderTitle({ value, remainQuantity }) || (
                <>
                  <div className="info-title desktop-only">
                    Ưu đãi lên đến {value}
                  </div>
                  <div className="info-title mobile-only tablet-only">
                    {remainQuantity} suất học bổng lên đến {value}
                  </div>
                </>
              )}

              {renderDescription({
                initialTime,
                value,
                remainQuantity,
                endDate
              }) || (
                <div className="info-desc">
                  <span className="desktop-only d-inline">
                    còn <span className="bold">{remainQuantity}</span> suất học
                    bổng,{' '}
                  </span>
                  {courseName && (
                    <span>
                      cho lớp <span className="bold">{courseName}</span>
                    </span>
                  )}{' '}
                  chương trình kết thúc sau{' '}
                  <span className="bold">
                    <Timer initialTime={initialTime} direction="backward">
                      {() => (
                        <span className="count-down">
                          <Timer.Days />d <Timer.Hours />h <Timer.Minutes />m{' '}
                          <Timer.Seconds />s
                        </span>
                      )}
                    </Timer>{' '}
                  </span>
                  {linkUrl && <Link href={linkUrl}> {linkText}</Link>}
                </div>
              )}
            </div>
            <Typography
              variant="regular/12-20"
              color={colorsV2.black80}
              className="promotion-desc"
            >
              *Ưu đãi HÈ đăng ký trước{' '}
              {moment(endDate).format(MONTH_NAME_FORMAT2)}
            </Typography>
          </motion.div>
        </motion.div>
      </FullTopBarMain>
    );
  }
);

const HeaderTopBar = ({
  item,
  currentPromotionIndex,
  showCompactMode,
  ...rest
}) => {
  const { observe } = useInView({
    onLeave: () => {
      showCompactMode(true);
    },
    onEnter: () => {
      showCompactMode(false);
    }
  });
  if (!item) {
    return null;
  }

  return (
    <ResponsiveLayoutWithSsr
      breakpointWithComponents={[
        {
          ssrDefault: true,
          renderComponent: <Skeleton />
        },
        {
          toBreakpoint: 1365,
          renderComponent: (
            <CompactTopBar
              currentPromotionIndex={currentPromotionIndex}
              data={item}
              isCompactMode={true}
              ref={observe}
              {...rest}
            />
          )
        },
        {
          fromBreakpoint: 1366,
          renderComponent: (
            <FullTopBar
              currentPromotionIndex={currentPromotionIndex}
              data={item}
              ref={observe}
              {...rest}
            />
          )
        }
      ]}
    ></ResponsiveLayoutWithSsr>
  );
};

const FixedTopBarScholarship = ({ data, ...rest }) => {
  const [color, setColor] = React.useState('red');
  const [currentPromotionIndex, setCurrentPromotionIndex] = React.useState(0);
  const [isCompactMode, showCompactMode] = React.useState(false);
  const { isDesktop } = useDeviceDetect3();

  const list = React.useMemo(() => {
    return data
      .map(item => {
        const { endDate } = item;
        const { remainSlot: remainQuantity } = item;
        const initialTime = Math.max(
          new Date(endDate).getTime() - new Date().getTime(),
          0
        );
        item.initialTime = initialTime;
        const isPromotionEmpty = remainQuantity === 0 || initialTime === 0;

        return isPromotionEmpty ? null : item;
      })
      .filter(Boolean);
  }, [data]);

  React.useEffect(() => {
    const elm = document.getElementById('header-root');
    if (elm && list.length) {
      if (isDesktop) {
        elm.style.opacity = 1;
      } else {
        elm.style.opacity = isCompactMode ? 0 : 1;
      }
    }
  }, [isDesktop, list.length, isCompactMode]);

  useInterval(() => {
    if (list.length > 1) {
      setColor(prevColor => (prevColor === 'red' ? 'blue' : 'red'));
      setCurrentPromotionIndex((currentPromotionIndex + 1) % list.length);
    }
  }, 5000);

  const item = list[currentPromotionIndex];

  return (
    <Main
      id="fixed-topbar-scholarship"
      className={cl({
        [`color-${color}`]: true
      })}
    >
      <FixTop className="scholarship-fix-top" hidden={!isCompactMode}>
        {item && (
          <CompactTopBar
            currentPromotionIndex={currentPromotionIndex}
            data={item}
            isCompactMode={isCompactMode}
            {...rest}
          />
        )}
        {/*<CompactCovidNoti isCompactMode={isCompactMode} />*/}
      </FixTop>
      <HeaderTopBar
        currentPromotionIndex={currentPromotionIndex}
        item={item}
        showCompactMode={showCompactMode}
        {...rest}
      />
      {/*<FullCovidNoti />*/}
    </Main>
  );
};
export default FixedTopBarScholarship;
